@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

:root {
    --red-color: #d43831;
    --blue-color: #0f7ce4;
    --bg-color: #fafafa;
    --main-bg-color: #fff;
    --input-bg-color: #fff;
    --error-text-color: #ff4d4f;
    --text-1-color: #2a2d2f;
    --text-2-color: #676e76;
    --text-3-color: rgba(0, 0, 0, 0.25);
    --line-color: #d9d9d9;
}

.form-login-main {
    font-family: 'Roboto', sans-serif;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    width: 100%;
    background: var(--bg-color) url('./assets/image/bg-light-pc.png') center / cover repeat;
}

@media screen and (min-width: 1200px) {
    .form-login-main {
        padding: 30px auto;
        max-width: 620px;
    }
}

.form-login-form {
    width: 620px;
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 1200px) {
    .form-login-form {
        padding: 60px 100px;
        background: var(--main-bg-color);
    }
}

.form-login-form h2 {
    margin-bottom: 32px;
    color: var(--text-1-color);
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    line-height: 19.19px;
}

@media screen and (min-width: 1200px) {
    .form-login-form h2 {
        font-size: 16px;
    }
}

.form-login-form .field {
    position: relative;
}

.form-login-form .field.required:before {
    position: absolute;
    top: 0;
    left: -2px;
    width: 10px;
    height: 34px;
    content: '*';
    color: var(--red-color);
    font-size: 22px;
    transform: translate(-100%) translate(-0.06rem);
}

@media screen and (min-width: 1200px) {
    .form-login-form .field.required:before {
        font-size: 14px;
    }
}

.form-login-form .field + .field {
    margin-top: 24px;
}

@media screen and (min-width: 1200px) {
    .form-login-form .field + .field {
        margin-top: 16px;
    }
}

.form-login-form input,
.form-login-form select {
    width: 100%;
    display: block;
    padding: 24px;
    border: 1px solid var(--line-color);
    border-radius: 2px;
    color: var(--text-1-color);
    font-size: 26px;
    line-height: 4px;
}

.form-login-form input:focus:not(:disabled),
.form-login-form input:hover:not(:disabled),
.form-login-form select:focus:not(:disabled),
.form-login-form select:hover:not(:disabled) {
    border-color: var(--red-color);
    box-shadow: 0 0 0 0.02rem rgba(212, 56, 49, 0.2);
}

.form-login-form input {
    background: var(--input-bg-color);
}

.form-login-form input::placeholder {
    opacity: 0.6;
}

.form-login-form input,
.form-login-form select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.form-login-form button,
.form-login-form input,
.form-login-form optgroup,
.form-login-form select,
.form-login-form textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.2;
    margin: 0;
    outline: none;
}

@media screen and (min-width: 1200px) {
    .form-login-form input,
    .form-login-form select {
        padding: 8px 12px;
        font-size: 16px;
        line-height: 24px;
    }
}

.form-login-form .forgot {
    margin: 30px 0 60px;
    font-size: 16px;
    align-self: flex-end;
}

@media screen and (min-width: 1200px) {
    .form-login-form .forgot {
        margin: 15px 0 30px;
        font-size: 14px;
    }
}

.form-login-form a {
    color: var(--blue-color);
    text-decoration: none;
}

.form-login-form a:focus,
.form-login-form a:hover {
    text-decoration: underline;
}

.form-login-form button[type='submit'] {
    width: 100%;
}

.form-login-form button,
.form-login-form [type='button'],
.form-login-form [type='reset'],
.form-login-form [type='submit'] {
    -webkit-appearance: button;
    appearance: button;
    background-color: transparent;
    background-image: none;
}

.form-login-form button {
    padding: 24px;
    border-radius: 2px;
    font-size: 26px;
    line-height: 40px;
}

@media screen and (min-width: 1200px) {
    .form-login-form button {
        padding: 8px 12px;
        border-radius: 2px;
        font-size: 16px;
        line-height: 24px;
    }
}

.form-login-form button,
.form-login-form [role='button'] {
    cursor: pointer;
}

.form-login-form button,
.form-login-form select {
    text-transform: none;
}

.form-login-form .primary {
    background: var(--red-color);
    border: 1px solid var(--red-color);
    color: #fff;
}

.form-login-form .primary:focus:not(:disabled),
.form-login-form .primary:hover:not(:disabled) {
    box-shadow: 0 0 0 2px rgba(212, 56, 49, 0.2);
}

.form-login-form .secondary {
    background: var(--main-bg-color);
    border: 1px solid var(--line-color);
    color: var(--text-2-color);
}

.form-login-form .register {
    width: 100%;
}

.form-login-form .secondary:focus:not(:disabled),
.form-login-form .secondary:hover:not(:disabled) {
    border-color: var(--red-color);
    box-shadow: 0 0 0 2px rgba(212, 56, 49, 0.2);
    color: var(--red-color);
}

.form-login-form .login {
    width: 100%;
}

.form-login-form .form-login-message-error {
    color: #d43831;
    font-size: 12px;
    margin-top: 5px;
}

.form-login-form .agreement {
    margin: 24px 0 40px;
    color: var(--text-3-color);
    font-size: 22px;
    text-align: center;
}

@media screen and (min-width: 1200px) {
    .form-login-form .agreement {
        margin: 16px 32px;
        font-size: 14px;
    }
}

@media screen and (max-width: 768px) {
    .form-login-form {
        width: 100%;
        padding: 0 20px;
    }

    .form-login-form h2 {
        font-size: 18px;
    }

    .form-login-form input {
        padding: 12px;
    }

    .form-login-form .forgot {
        margin: 15px 0 30px;
        font-size: 13px;
    }

    .form-login-form .login {
        padding: 12px;
        font-size: 13px;
        line-height: 20px;
    }

    .form-login-form .register {
        padding: 12px;
        font-size: 13px;
        line-height: 20px;
    }

    .form-login-form input::placeholder {
        opacity: 0.6;
        font-size: 13px;
    }

    .form-login-form .field + .field {
        margin-top: 12px;
    }

    .form-login-form .field.required:before {
        width: 8px;
        font-size: 14px;
    }

    .form-login-form .agreement {
        font-size: 12px;
        margin: 12px 0 20px;
    }
}
