.default-website {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.default-website .text {
    color: rgba(160, 174, 192, 1);
    font-size: 20px;
    letter-spacing: 0.6px;
}

.default-website .code {
    padding: 0 14px;
    border-right: 1px solid rgba(160, 174, 192, 1);
    line-height: 1;
}

.default-website .message {
    margin-left: 14px;
}

.loading-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
    user-select: none;
}

.loading-content {
    background-size: 100% 100%;
    margin: 30px auto;
    width: 30px;
    height: 30px;
    background-image: url('./assets/image/loading.png');
    animation: Loading 1s linear infinite;
}

@keyframes Loading {
    100% {
        transform: rotate(1turn);
    }
}
