@font-face {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 100;
    src: local('Metropolis Thin'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-Thin-e87b67ad.woff2) format('woff2'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-Thin-d4c2bfc3.woff) format('woff');
}
@font-face {
    font-family: Metropolis;
    font-style: italic;
    font-weight: 100;
    src: local('Metropolis Thin Italic'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-ThinItalic-f0ba6899.woff2) format('woff2'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-ThinItalic-31ba4bb1.woff) format('woff');
}
@font-face {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 200;
    src: local('Metropolis Extra Light'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-ExtraLight-56376682.woff2) format('woff2'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-ExtraLight-0da5acea.woff) format('woff');
}
@font-face {
    font-family: Metropolis;
    font-style: italic;
    font-weight: 200;
    src: local('Metropolis Extra Light Italic'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-ExtraLightItalic-cb089003.woff2) format('woff2'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-ExtraLightItalic-1f9daf9d.woff) format('woff');
}
@font-face {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 300;
    src: local('Metropolis Light'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-Light-3664cf60.woff2) format('woff2'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-Light-79b15696.woff) format('woff');
}
@font-face {
    font-family: Metropolis;
    font-style: italic;
    font-weight: 300;
    src: local('Metropolis Light Italic'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-LightItalic-c7ea8d33.woff2) format('woff2'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-LightItalic-114d5afe.woff) format('woff');
}
@font-face {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 400;
    src: local('Metropolis Regular'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-Regular-e920e6b0.woff2) format('woff2'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-Regular-b19e8951.woff) format('woff');
}
@font-face {
    font-family: Metropolis;
    font-style: italic;
    font-weight: 400;
    src: local('Metropolis Regular Italic'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-RegularItalic-ccc6ad34.woff2) format('woff2'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-RegularItalic-77f052dc.woff) format('woff');
}
@font-face {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 500;
    src: local('Metropolis Medium'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-Medium-f3a73873.woff2) format('woff2'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-Medium-91cf497b.woff) format('woff');
}
@font-face {
    font-family: Metropolis;
    font-style: italic;
    font-weight: 500;
    src: local('Metropolis Medium Italic'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-MediumItalic-f604beac.woff2) format('woff2'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-MediumItalic-7ed18e53.woff) format('woff');
}
@font-face {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 600;
    src: local('Metropolis Semi Bold'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-SemiBold-695870d1.woff2) format('woff2'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-SemiBold-bd9cc6de.woff) format('woff');
}
@font-face {
    font-family: Metropolis;
    font-style: italic;
    font-weight: 600;
    src: local('Metropolis Semi Bold Italic'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-SemiBoldItalic-f08b947a.woff2) format('woff2'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-SemiBoldItalic-5e65c493.woff) format('woff');
}
@font-face {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 700;
    src: local('Metropolis Bold'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-Bold-9a712a2c.woff2) format('woff2'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-Bold-cd1f8382.woff) format('woff');
}
@font-face {
    font-family: Metropolis;
    font-style: italic;
    font-weight: 700;
    src: local('Metropolis Bold Italic'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-BoldItalic-6f0a19e8.woff2) format('woff2'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-BoldItalic-3f088a11.woff) format('woff');
}
@font-face {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 800;
    src: local('Metropolis Extra Bold'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-ExtraBold-0c9ff630.woff2) format('woff2'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-ExtraBold-f79c09f3.woff) format('woff');
}
@font-face {
    font-family: Metropolis;
    font-style: italic;
    font-weight: 800;
    src: local('Metropolis Extra Bold Italic'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-ExtraBoldItalic-8ef75318.woff2) format('woff2'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-ExtraBoldItalic-b4e62ca3.woff) format('woff');
}
@font-face {
    font-family: Metropolis;
    font-style: normal;
    font-weight: 900;
    src: local('Metropolis Black'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-Black-bcd0ced5.woff2) format('woff2'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-Black-ece44382.woff) format('woff');
}
@font-face {
    font-family: Metropolis;
    font-style: italic;
    font-weight: 900;
    src: local('Metropolis Black Italic'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-BlackItalic-fadeacd3.woff2) format('woff2'),
        url(https://cdn-gop.garenanow.com/gop/mshop/www/live/assets/Metropolis-BlackItalic-fc2225d4.woff) format('woff');
}
*,
:before,
:after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
}
:before,
:after {
    --tw-content: '';
}
html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', Segoe UI Symbol, 'Noto Color Emoji';
    font-feature-settings: normal;
    font-variation-settings: normal;
}
body {
    margin: 0;
    line-height: inherit;
}
hr {
    height: 0;
    color: inherit;
    border-top-width: 1px;
}
abbr:where([title]) {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
}
a {
    color: inherit;
    text-decoration: inherit;
}
b,
strong {
    font-weight: bolder;
}
code,
kbd,
samp,
pre {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-size: 1em;
}
small {
    font-size: 80%;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
}
button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
}
button,
select {
    text-transform: none;
}
button,
[type='button'],
[type='reset'],
[type='submit'] {
    appearance: button;
    -webkit-appearance: button;
    background-color: transparent;
    background-image: none;
}
:-moz-focusring {
    outline: auto;
}
:-moz-ui-invalid {
    box-shadow: none;
}
progress {
    vertical-align: baseline;
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto;
}
[type='search'] {
    appearance: textfield;
    -webkit-appearance: textfield;
    outline-offset: -2px;
}
::-webkit-search-decoration {
    -webkit-appearance: none;
}
::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}
summary {
    display: list-item;
}
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
    margin: 0;
}
fieldset {
    margin: 0;
    padding: 0;
}
legend {
    padding: 0;
}
ol,
ul,
menu {
    list-style: none;
    margin: 0;
    padding: 0;
}
dialog {
    padding: 0;
}
textarea {
    resize: vertical;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
    opacity: 1;
    color: #9ca3af;
}
input::placeholder,
textarea::placeholder {
    opacity: 1;
    color: #9ca3af;
}
button,
[role='button'] {
    cursor: pointer;
}
:disabled {
    cursor: default;
}
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
}
img,
video {
    max-width: 100%;
    height: auto;
}
[hidden] {
    display: none;
}
html,
body {
    height: 100%;
    overscroll-behavior: none;
}
html {
    font-family: Metropolis, Noto Sans Thai, Noto Sans Arabic, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
        Helvetica Neue, Arial, Noto Sans, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', Segoe UI Symbol, 'Noto Color Emoji';
    --tw-text-opacity: 1;
    color: rgb(50 50 50 / var(--tw-text-opacity));
}
html.dark {
    --tw-bg-opacity: 1;
    background-color: rgb(40 41 56 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(211 211 211 / var(--tw-text-opacity));
}
body,
#root {
    display: flex;
    flex-direction: column;
}
#root {
    flex: 1 1 0%;
}
:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
*,
:before,
:after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
}
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
}
.\!container {
    width: 100% !important;
}
.container {
    width: 100%;
}
@media (min-width: 640px) {
    .\!container {
        max-width: 640px !important;
    }
    .container {
        max-width: 640px;
    }
}
@media (min-width: 768px) {
    .\!container {
        max-width: 768px !important;
    }
    .container {
        max-width: 768px;
    }
}
@media (min-width: 1024px) {
    .\!container {
        max-width: 1024px !important;
    }
    .container {
        max-width: 1024px;
    }
}
@media (min-width: 1280px) {
    .\!container {
        max-width: 1280px !important;
    }
    .container {
        max-width: 1280px;
    }
}
@media (min-width: 1536px) {
    .\!container {
        max-width: 1536px !important;
    }
    .container {
        max-width: 1536px;
    }
}
.form-input {
    display: block;
    height: 2.75rem;
    border-radius: 0.375rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(211 211 211 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding: 0.875rem;
    font-size: 0.875rem;
    line-height: 1;
    --tw-text-opacity: 1;
    color: rgb(50 50 50 / var(--tw-text-opacity));
    caret-color: #d81a0d;
    outline: 2px solid transparent;
    outline-offset: 2px;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
}
.form-input:focus {
    --tw-border-opacity: 1;
    border-color: rgb(153 153 159 / var(--tw-border-opacity));
}
.form-input:placeholder {
    --tw-text-opacity: 1;
    color: rgb(201 201 201 / var(--tw-text-opacity));
}
.form-input:disabled {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 245 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(201 201 201 / var(--tw-text-opacity));
}
.form-input:invalid {
    --tw-border-opacity: 1;
    border-color: rgb(216 26 13 / var(--tw-border-opacity));
}
.form-input:invalid:focus {
    --tw-border-opacity: 1;
    border-color: rgb(216 26 13 / var(--tw-border-opacity));
}
.form-input-placeholder {
    --tw-text-opacity: 1;
    color: rgb(201 201 201 / var(--tw-text-opacity));
}
.form-input-invalid {
    --tw-border-opacity: 1;
    border-color: rgb(216 26 13 / var(--tw-border-opacity));
}
.form-input-invalid:focus {
    --tw-border-opacity: 1;
    border-color: rgb(216 26 13 / var(--tw-border-opacity));
}
.pointer-events-none {
    pointer-events: none;
}
.pointer-events-auto {
    pointer-events: auto;
}
.\!visible {
    visibility: visible !important;
}
.visible {
    visibility: visible;
}
.invisible {
    visibility: hidden;
}
.fixed {
    position: fixed;
}
.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
.sticky {
    position: sticky;
}
.inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
}
.inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem;
}
.inset-x-0 {
    left: 0;
    right: 0;
}
.inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
}
.inset-y-0 {
    top: 0;
    bottom: 0;
}
.inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem;
}
.inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem;
}
.inset-y-auto {
    top: auto;
    bottom: auto;
}
.-end-0 {
    inset-inline-end: -0px;
}
.-end-0\.5 {
    inset-inline-end: -0.125rem;
}
.-end-4 {
    inset-inline-end: -1rem;
}
.-end-\[13px\] {
    inset-inline-end: -13px;
}
.bottom-0 {
    bottom: 0;
}
.bottom-10 {
    bottom: 2.5rem;
}
.bottom-2 {
    bottom: 0.5rem;
}
.bottom-2\.5 {
    bottom: 0.625rem;
}
.bottom-8 {
    bottom: 2rem;
}
.bottom-full {
    bottom: 100%;
}
.end-0 {
    inset-inline-end: 0px;
}
.end-2 {
    inset-inline-end: 0.5rem;
}
.end-3 {
    inset-inline-end: 0.75rem;
}
.end-3\.5 {
    inset-inline-end: 0.875rem;
}
.end-4 {
    inset-inline-end: 1rem;
}
.end-6 {
    inset-inline-end: 1.5rem;
}
.end-\[-52px\] {
    inset-inline-end: -52px;
}
.end-\[3px\] {
    inset-inline-end: 3px;
}
.start-0 {
    inset-inline-start: 0px;
}
.start-1\/2 {
    inset-inline-start: 50%;
}
.start-2 {
    inset-inline-start: 0.5rem;
}
.start-2\.5 {
    inset-inline-start: 0.625rem;
}
.start-2\/4 {
    inset-inline-start: 50%;
}
.start-4 {
    inset-inline-start: 1rem;
}
.start-5 {
    inset-inline-start: 1.25rem;
}
.top-0 {
    top: 0;
}
.top-1 {
    top: 0.25rem;
}
.top-1\/2 {
    top: 50%;
}
.top-14 {
    top: 3.5rem;
}
.top-2 {
    top: 0.5rem;
}
.top-2\/4 {
    top: 50%;
}
.top-3 {
    top: 0.75rem;
}
.top-4 {
    top: 1rem;
}
.top-5 {
    top: 1.25rem;
}
.top-6 {
    top: 1.5rem;
}
.top-\[-10px\] {
    top: -10px;
}
.top-\[3px\] {
    top: 3px;
}
.top-full {
    top: 100%;
}
.z-10 {
    z-index: 10;
}
.z-50 {
    z-index: 50;
}
.z-\[1\] {
    z-index: 1;
}
.col-span-2 {
    grid-column: span 2 / span 2;
}
.col-span-full {
    grid-column: 1 / -1;
}
.col-start-1 {
    grid-column-start: 1;
}
.row-start-1 {
    grid-row-start: 1;
}
.-m-2 {
    margin: -0.5rem;
}
.m-1 {
    margin: 0.25rem;
}
.m-auto {
    margin: auto;
}
.-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
}
.mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}
.mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}
.my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}
.my-\[82px\] {
    margin-top: 82px;
    margin-bottom: 82px;
}
.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}
.-mb-1 {
    margin-bottom: -0.25rem;
}
.-mb-1\.5 {
    margin-bottom: -0.375rem;
}
.-ms-0 {
    margin-inline-start: -0px;
}
.-ms-0\.5 {
    margin-inline-start: -0.125rem;
}
.-ms-1 {
    margin-inline-start: -0.25rem;
}
.-ms-1\.5 {
    margin-inline-start: -0.375rem;
}
.-ms-12 {
    margin-inline-start: -3rem;
}
.-mt-2 {
    margin-top: -0.5rem;
}
.-mt-4 {
    margin-top: -1rem;
}
.-mt-9 {
    margin-top: -2.25rem;
}
.mb-1 {
    margin-bottom: 0.25rem;
}
.mb-1\.5 {
    margin-bottom: 0.375rem;
}
.mb-2 {
    margin-bottom: 0.5rem;
}
.mb-2\.5 {
    margin-bottom: 0.625rem;
}
.mb-3 {
    margin-bottom: 0.75rem;
}
.mb-3\.5 {
    margin-bottom: 0.875rem;
}
.mb-4 {
    margin-bottom: 1rem;
}
.mb-5 {
    margin-bottom: 1.25rem;
}
.mb-6 {
    margin-bottom: 1.5rem;
}
.mb-7 {
    margin-bottom: 1.75rem;
}
.mb-8 {
    margin-bottom: 2rem;
}
.mb-\[22px\] {
    margin-bottom: 22px;
}
.me-1 {
    margin-inline-end: 0.25rem;
}
.me-1\.5 {
    margin-inline-end: 0.375rem;
}
.me-2 {
    margin-inline-end: 0.5rem;
}
.me-2\.5 {
    margin-inline-end: 0.625rem;
}
.me-3 {
    margin-inline-end: 0.75rem;
}
.me-4 {
    margin-inline-end: 1rem;
}
.me-auto {
    margin-inline-end: auto;
}
.ms-0 {
    margin-inline-start: 0px;
}
.ms-0\.5 {
    margin-inline-start: 0.125rem;
}
.ms-1 {
    margin-inline-start: 0.25rem;
}
.ms-1\.5 {
    margin-inline-start: 0.375rem;
}
.ms-2 {
    margin-inline-start: 0.5rem;
}
.ms-2\.5 {
    margin-inline-start: 0.625rem;
}
.ms-3 {
    margin-inline-start: 0.75rem;
}
.ms-4 {
    margin-inline-start: 1rem;
}
.ms-5 {
    margin-inline-start: 1.25rem;
}
.ms-auto {
    margin-inline-start: auto;
}
.mt-0 {
    margin-top: 0;
}
.mt-0\.5 {
    margin-top: 0.125rem;
}
.mt-1 {
    margin-top: 0.25rem;
}
.mt-1\.5 {
    margin-top: 0.375rem;
}
.mt-2 {
    margin-top: 0.5rem;
}
.mt-2\.5 {
    margin-top: 0.625rem;
}
.mt-3 {
    margin-top: 0.75rem;
}
.mt-4 {
    margin-top: 1rem;
}
.mt-5 {
    margin-top: 1.25rem;
}
.mt-6 {
    margin-top: 1.5rem;
}
.mt-7 {
    margin-top: 1.75rem;
}
.mt-8 {
    margin-top: 2rem;
}
.mt-9 {
    margin-top: 2.25rem;
}
.mt-10 {
    margin-top: 5rem;
}
.mt-\[22px\] {
    margin-top: 22px;
}
.mt-auto {
    margin-top: auto;
}
.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
}
.block {
    display: block;
}
.inline-block {
    display: inline-block;
}
.inline {
    display: inline;
}
.flex {
    display: flex;
}
.inline-flex {
    display: inline-flex;
}
.grid {
    display: grid;
}
.hidden {
    display: none;
}
.h-0 {
    height: 0px;
}
.h-1 {
    height: 0.25rem;
}
.h-1\.5 {
    height: 0.375rem;
}
.h-10 {
    height: 2.5rem;
}
.h-11 {
    height: 2.75rem;
}
.h-12 {
    height: 3rem;
}
.h-14 {
    height: 3.5rem;
}
.h-2 {
    height: 0.5rem;
}
.h-2\.5 {
    height: 0.625rem;
}
.h-20 {
    height: 5rem;
}
.h-24 {
    height: 6rem;
}
.h-3 {
    height: 0.75rem;
}
.h-3\.5 {
    height: 0.875rem;
}
.h-3\/4 {
    height: 75%;
}
.h-4 {
    height: 1rem;
}
.h-5 {
    height: 1.25rem;
}
.h-6 {
    height: 1.5rem;
}
.h-7 {
    height: 1.75rem;
}
.h-72 {
    height: 18rem;
}
.h-8 {
    height: 2rem;
}
.h-9 {
    height: 2.25rem;
}
.h-\[100vh\] {
    height: 100vh;
}
.h-\[110px\] {
    height: 110px;
}
.h-\[111px\] {
    height: 111px;
}
.h-\[140px\] {
    height: 140px;
}
.h-\[150px\] {
    height: 150px;
}
.h-\[171px\] {
    height: 171px;
}
.h-\[18px\] {
    height: 18px;
}
.h-\[22px\] {
    height: 22px;
}
.h-\[246px\] {
    height: 246px;
}
.h-\[27px\] {
    height: 27px;
}
.h-\[30px\] {
    height: 30px;
}
.h-\[34px\] {
    height: 34px;
}
.h-\[42px\] {
    height: 42px;
}
.h-\[46px\] {
    height: 46px;
}
.h-\[50px\] {
    height: 50px;
}
.h-\[72px\] {
    height: 72px;
}
.h-\[7px\] {
    height: 7px;
}
.h-fit {
    height: -moz-fit-content;
    height: fit-content;
}
.h-full {
    height: 100%;
}
.max-h-\[230px\] {
    max-height: 230px;
}
.max-h-\[412px\] {
    max-height: 412px;
}
.max-h-\[60vh\] {
    max-height: 60vh;
}
.max-h-\[90\%\] {
    max-height: 90%;
}
.max-h-full {
    max-height: 100%;
}
.min-h-\[250px\] {
    min-height: 250px;
}
.min-h-\[350px\] {
    min-height: 350px;
}
.min-h-\[50px\] {
    min-height: 50px;
}
.min-h-\[80px\] {
    min-height: 80px;
}
.min-h-full {
    min-height: 100%;
}
.w-1 {
    width: 0.25rem;
}
.w-1\.5 {
    width: 0.375rem;
}
.w-1\/2 {
    width: 50%;
}
.w-10 {
    width: 2.5rem;
}
.w-11 {
    width: 2.75rem;
}
.w-12 {
    width: 3rem;
}
.w-2 {
    width: 0.5rem;
}
.w-20 {
    width: 5rem;
}
.w-24 {
    width: 6rem;
}
.w-3 {
    width: 0.75rem;
}
.w-3\.5 {
    width: 0.875rem;
}
.w-3\/4 {
    width: 75%;
}
.w-4 {
    width: 1rem;
}
.w-5 {
    width: 1.25rem;
}
.w-6 {
    width: 1.5rem;
}
.w-8 {
    width: 2rem;
}
.w-9 {
    width: 2.25rem;
}
.w-\[100px\] {
    width: 100px;
}
.w-\[100vmin\] {
    width: 100vmin;
}
.w-\[120px\] {
    width: 120px;
}
.w-\[150px\] {
    width: 150px;
}
.w-\[18px\] {
    width: 18px;
}
.w-\[21\.783\%\] {
    width: 21.783%;
}
.w-\[22px\] {
    width: 22px;
}
.w-\[280px\] {
    width: 280px;
}
.w-\[286px\] {
    width: 286px;
}
.w-\[30px\] {
    width: 30px;
}
.w-\[34px\] {
    width: 34px;
}
.w-\[390px\] {
    width: 390px;
}
.w-\[400px\] {
    width: 400px;
}
.w-\[50px\] {
    width: 50px;
}
.w-\[60px\] {
    width: 60px;
}
.w-\[72px\] {
    width: 72px;
}
.w-full {
    width: 100%;
}
.w-px {
    width: 1px;
}
.w-screen {
    width: 100vw;
}
.min-w-0 {
    min-width: 0px;
}
.min-w-\[98px\] {
    min-width: 98px;
}
.max-w-5xl {
    max-width: 64rem;
}
.max-w-6xl {
    max-width: 72rem;
}
.max-w-72 {
    max-width: 18rem;
}
.max-w-80 {
    max-width: 20rem;
}
.max-w-\[1366px\] {
    max-width: 1366px;
}
.max-w-\[270px\] {
    max-width: 270px;
}
.max-w-\[310px\] {
    max-width: 310px;
}
.max-w-\[600px\] {
    max-width: 600px;
}
.max-w-\[70px\] {
    max-width: 70px;
}
.max-w-\[90\%\] {
    max-width: 90%;
}
.max-w-\[900px\] {
    max-width: 900px;
}
.max-w-full {
    max-width: 100%;
}
.max-w-lg {
    max-width: 32rem;
}
.max-w-md {
    max-width: 28rem;
}
.max-w-xs {
    max-width: 20rem;
}
.flex-1 {
    flex: 1 1 0%;
}
.shrink {
    flex-shrink: 1;
}
.shrink-0 {
    flex-shrink: 0;
}
.grow {
    flex-grow: 1;
}
.origin-bottom {
    transform-origin: bottom;
}
.origin-top {
    transform-origin: top;
}
.origin-top-left {
    transform-origin: top left;
}
.origin-top-right {
    transform-origin: top right;
}
.-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-x-full {
    --tw-translate-x: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-1\/2 {
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-full {
    --tw-translate-y: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-full {
    --tw-translate-x: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-1 {
    --tw-translate-y: 0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-full {
    --tw-translate-y: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-rotate-180 {
    --tw-rotate: -180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-rotate-90 {
    --tw-rotate: -90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-90 {
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.skew-x-\[-30deg\] {
    --tw-skew-x: -30deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.skew-x-\[30deg\] {
    --tw-skew-x: 30deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-50 {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-90 {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-95 {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-\[45\%\] {
    --tw-scale-x: 45%;
    --tw-scale-y: 45%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-scale-x-100 {
    --tw-scale-x: -1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-x-100 {
    --tw-scale-x: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-y-75 {
    --tw-scale-y: 0.75;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes pulse {
    50% {
        opacity: 0.5;
    }
}
.animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
.animate-spin {
    animation: spin 1s linear infinite;
}
.cursor-not-allowed {
    cursor: not-allowed;
}
.cursor-pointer {
    cursor: pointer;
}
.select-all {
    -webkit-user-select: all;
    -moz-user-select: all;
    user-select: all;
}
.snap-x {
    scroll-snap-type: x var(--tw-scroll-snap-strictness);
}
.snap-mandatory {
    --tw-scroll-snap-strictness: mandatory;
}
.snap-center {
    scroll-snap-align: center;
}
.scroll-mt-16 {
    scroll-margin-top: 4rem;
}
.scroll-mt-36 {
    scroll-margin-top: 9rem;
}
.scroll-mt-40 {
    scroll-margin-top: 10rem;
}
.list-decimal {
    list-style-type: decimal;
}
.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
}
.flex-col {
    flex-direction: column;
}
.flex-wrap {
    flex-wrap: wrap;
}
.items-start {
    align-items: flex-start;
}
.items-end {
    align-items: flex-end;
}
.items-center {
    align-items: center;
}
.items-stretch {
    align-items: stretch;
}
.justify-start {
    justify-content: flex-start;
}
.justify-end {
    justify-content: flex-end;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.justify-items-start {
    justify-items: start;
}
.justify-items-end {
    justify-items: end;
}
.justify-items-center {
    justify-items: center;
}
.gap-0 {
    gap: 0px;
}
.gap-0\.5 {
    gap: 0.125rem;
}
.gap-1 {
    gap: 0.25rem;
}
.gap-1\.5 {
    gap: 0.375rem;
}
.gap-10 {
    gap: 2.5rem;
}
.gap-12 {
    gap: 3rem;
}
.gap-2 {
    gap: 0.5rem;
}
.gap-2\.5 {
    gap: 0.625rem;
}
.gap-3 {
    gap: 0.75rem;
}
.gap-3\.5 {
    gap: 0.875rem;
}
.gap-4 {
    gap: 1rem;
}
.gap-5 {
    gap: 1.25rem;
}
.gap-6 {
    gap: 1.5rem;
}
.gap-7 {
    gap: 1.75rem;
}
.gap-8 {
    gap: 2rem;
}
.gap-9 {
    gap: 2.25rem;
}
.gap-x-0 {
    -moz-column-gap: 0px;
    column-gap: 0px;
}
.gap-x-0\.5 {
    -moz-column-gap: 0.125rem;
    column-gap: 0.125rem;
}
.gap-x-3 {
    -moz-column-gap: 0.75rem;
    column-gap: 0.75rem;
}
.gap-x-3\.5 {
    -moz-column-gap: 0.875rem;
    column-gap: 0.875rem;
}
.gap-x-4 {
    -moz-column-gap: 1rem;
    column-gap: 1rem;
}
.gap-x-\[22px\] {
    -moz-column-gap: 22px;
    column-gap: 22px;
}
.gap-y-1 {
    row-gap: 0.25rem;
}
.gap-y-4 {
    row-gap: 1rem;
}
.gap-y-6 {
    row-gap: 1.5rem;
}
.space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}
.self-center {
    align-self: center;
}
.overflow-auto {
    overflow: auto;
}
.overflow-hidden {
    overflow: hidden;
}
.overflow-y-auto {
    overflow-y: auto;
}
.overflow-x-hidden {
    overflow-x: hidden;
}
.overflow-x-scroll {
    overflow-x: scroll;
}
.text-ellipsis {
    text-overflow: ellipsis;
}
.whitespace-nowrap {
    white-space: nowrap;
}
.whitespace-pre-wrap {
    white-space: pre-wrap;
}
.break-words {
    overflow-wrap: break-word;
}
.rounded {
    border-radius: 0.25rem;
}
.rounded-\[25\%\] {
    border-radius: 25%;
}
.rounded-\[3px\] {
    border-radius: 3px;
}
.rounded-\[5px\] {
    border-radius: 5px;
}
.rounded-full {
    border-radius: 9999px;
}
.rounded-lg {
    border-radius: 0.5rem;
}
.rounded-md {
    border-radius: 0.375rem;
}
.rounded-sm {
    border-radius: 0.125rem;
}
.rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}
.rounded-e-md {
    border-start-end-radius: 0.375rem;
    border-end-end-radius: 0.375rem;
}
.rounded-e-none {
    border-start-end-radius: 0px;
    border-end-end-radius: 0px;
}
.rounded-s-\[3px\] {
    border-start-start-radius: 3px;
    border-end-start-radius: 3px;
}
.rounded-s-md {
    border-start-start-radius: 0.375rem;
    border-end-start-radius: 0.375rem;
}
.rounded-s-none {
    border-start-start-radius: 0px;
    border-end-start-radius: 0px;
}
.rounded-t-\[14px\] {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
}
.rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}
.rounded-ss-\[50\%\] {
    border-start-start-radius: 50%;
}
.border {
    border-width: 1px;
}
.border-2 {
    border-width: 2px;
}
.border-4 {
    border-width: 4px;
}
.border-\[0\.5px\] {
    border-width: 0.5px;
}
.border-\[3px\] {
    border-width: 3px;
}
.border-x-2 {
    border-left-width: 2px;
    border-right-width: 2px;
}
.border-x-4 {
    border-left-width: 4px;
    border-right-width: 4px;
}
.border-y-2 {
    border-top-width: 2px;
    border-bottom-width: 2px;
}
.border-y-8 {
    border-top-width: 8px;
    border-bottom-width: 8px;
}
.border-y-\[9px\] {
    border-top-width: 9px;
    border-bottom-width: 9px;
}
.border-b {
    border-bottom-width: 1px;
}
.border-e {
    border-inline-end-width: 1px;
}
.border-e-0 {
    border-inline-end-width: 0px;
}
.border-l-0 {
    border-left-width: 0px;
}
.border-r-0 {
    border-right-width: 0px;
}
.border-t {
    border-top-width: 1px;
}
.border-solid {
    border-style: solid;
}
.border-\[\#C1C2C8\] {
    --tw-border-opacity: 1;
    border-color: rgb(193 194 200 / var(--tw-border-opacity));
}
.border-\[\#E5E5E5\] {
    --tw-border-opacity: 1;
    border-color: rgb(229 229 229 / var(--tw-border-opacity));
}
.border-\[\#E6252D\] {
    --tw-border-opacity: 1;
    border-color: rgb(230 37 45 / var(--tw-border-opacity));
}
.border-\[\#bdbdbd\] {
    --tw-border-opacity: 1;
    border-color: rgb(189 189 189 / var(--tw-border-opacity));
}
.border-box-border {
    --tw-border-opacity: 1;
    border-color: rgb(211 211 211 / var(--tw-border-opacity));
}
.border-box-border-focus {
    --tw-border-opacity: 1;
    border-color: rgb(153 153 159 / var(--tw-border-opacity));
}
.border-box-border\/50 {
    border-color: rgba(211, 211, 211, 0.5);
}
.border-line {
    --tw-border-opacity: 1;
    border-color: rgb(238 238 238 / var(--tw-border-opacity));
}
.border-line\/50 {
    border-color: rgba(238, 238, 238, 0.5);
}
.border-primary-red {
    --tw-border-opacity: 1;
    border-color: rgb(216 26 13 / var(--tw-border-opacity));
}
.border-primary-red-hover {
    --tw-border-opacity: 1;
    border-color: rgb(205 18 20 / var(--tw-border-opacity));
}
.border-text-title {
    --tw-border-opacity: 1;
    border-color: rgb(40 41 47 / var(--tw-border-opacity));
}
.border-title-line {
    --tw-border-opacity: 1;
    border-color: rgb(223 223 223 / var(--tw-border-opacity));
}
.border-title-line\/50 {
    border-color: rgba(223, 223, 223, 0.5);
}
.border-transparent {
    border-color: transparent;
}
.border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.border-white\/60 {
    border-color: rgba(255, 255, 255, 0.6);
}
.border-b-line\/50 {
    border-bottom-color: rgba(238, 238, 238, 0.5);
}
.border-b-transparent {
    border-bottom-color: transparent;
}
.border-e-title-line {
    --tw-border-opacity: 1;
    border-inline-end-color: rgb(223 223 223 / var(--tw-border-opacity));
}
.border-e-transparent {
    border-inline-end-color: transparent;
}
.border-s-transparent {
    border-inline-start-color: transparent;
}
.border-t-\[\#DFDFDF\]\/50 {
    border-top-color: rgba(223, 223, 223, 0.5);
}
.border-t-neutral-700 {
    --tw-border-opacity: 1;
    border-top-color: rgb(64 64 64 / var(--tw-border-opacity));
}
.border-t-transparent {
    border-top-color: transparent;
}
.bg-\[\#006AFC\] {
    --tw-bg-opacity: 1;
    background-color: rgb(0 106 252 / var(--tw-bg-opacity));
}
.bg-\[\#0077FF\] {
    --tw-bg-opacity: 1;
    background-color: rgb(0 119 255 / var(--tw-bg-opacity));
}
.bg-\[\#151515\] {
    --tw-bg-opacity: 1;
    background-color: rgb(21 21 21 / var(--tw-bg-opacity));
}
.bg-\[\#3A96ED\] {
    --tw-bg-opacity: 1;
    background-color: rgb(58 150 237 / var(--tw-bg-opacity));
}
.bg-\[\#BDBDC5\] {
    --tw-bg-opacity: 1;
    background-color: rgb(189 189 197 / var(--tw-bg-opacity));
}
.bg-\[\#DFDFDF\] {
    --tw-bg-opacity: 1;
    background-color: rgb(223 223 223 / var(--tw-bg-opacity));
}
.bg-\[\#E6252D\] {
    --tw-bg-opacity: 1;
    background-color: rgb(230 37 45 / var(--tw-bg-opacity));
}
.bg-\[\#EFEFEF\] {
    --tw-bg-opacity: 1;
    background-color: rgb(239 239 239 / var(--tw-bg-opacity));
}
.bg-\[\#F2B13E\] {
    --tw-bg-opacity: 1;
    background-color: rgb(242 177 62 / var(--tw-bg-opacity));
}
.bg-\[\#F2F2F6\] {
    --tw-bg-opacity: 1;
    background-color: rgb(242 242 246 / var(--tw-bg-opacity));
}
.bg-\[\#F3F3F3\] {
    --tw-bg-opacity: 1;
    background-color: rgb(243 243 243 / var(--tw-bg-opacity));
}
.bg-\[\#F4F4F4\] {
    --tw-bg-opacity: 1;
    background-color: rgb(244 244 244 / var(--tw-bg-opacity));
}
.bg-\[\#F7F7F7\] {
    --tw-bg-opacity: 1;
    background-color: rgb(247 247 247 / var(--tw-bg-opacity));
}
.bg-\[\#F9F9F9\] {
    --tw-bg-opacity: 1;
    background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}
.bg-\[\#FDD373\] {
    --tw-bg-opacity: 1;
    background-color: rgb(253 211 115 / var(--tw-bg-opacity));
}
.bg-\[\#FFC51F\]\/\[0\.388393\] {
    background-color: rgba(255, 197, 31, 0.39);
}
.bg-\[\#FFE9AD\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 233 173 / var(--tw-bg-opacity));
}
.bg-\[\#FFECBA\]\/\[0\.4576\] {
    background-color: rgba(255, 236, 186, 0.46);
}
.bg-\[\#FFF4F4\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 244 244 / var(--tw-bg-opacity));
}
.bg-\[\#FFF5F5\] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 245 245 / var(--tw-bg-opacity));
}
.bg-\[\#efefef\] {
    --tw-bg-opacity: 1;
    background-color: rgb(239 239 239 / var(--tw-bg-opacity));
}
.bg-black\/50 {
    background-color: rgba(0, 0, 0, 0.5);
}
.bg-black\/60 {
    background-color: rgba(0, 0, 0, 0.6);
}
.bg-black\/70 {
    background-color: rgba(0, 0, 0, 0.7);
}
.bg-black\/75 {
    background-color: rgba(0, 0, 0, 0.75);
}
.bg-black\/80 {
    background-color: rgba(0, 0, 0, 0.8);
}
.bg-black\/\[0\.65\] {
    background-color: rgba(0, 0, 0, 0.65);
}
.bg-dark-bg\/75 {
    background-color: rgba(0, 8, 30, 0.75);
}
.bg-line {
    --tw-bg-opacity: 1;
    background-color: rgb(238 238 238 / var(--tw-bg-opacity));
}
.bg-neutral-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}
.bg-neutral-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(212 212 212 / var(--tw-bg-opacity));
}
.bg-neutral-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}
.bg-primary-red {
    --tw-bg-opacity: 1;
    background-color: rgb(216 26 13 / var(--tw-bg-opacity));
}
.bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}
.bg-selected-bg {
    --tw-bg-opacity: 1;
    background-color: rgb(255 250 250 / var(--tw-bg-opacity));
}
.bg-text-title {
    --tw-bg-opacity: 1;
    background-color: rgb(40 41 47 / var(--tw-bg-opacity));
}
.bg-title-line {
    --tw-bg-opacity: 1;
    background-color: rgb(223 223 223 / var(--tw-bg-opacity));
}
.bg-transparent {
    background-color: transparent;
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-white\/80 {
    background-color: rgba(255, 255, 255, 0.8);
}
.bg-\[linear-gradient\(105deg\,\#FF586A_0\%\,\#FF2533_100\%\)\] {
    background-image: linear-gradient(105deg, #ff586a 0%, #ff2533 100%);
}
.bg-\[linear-gradient\(135deg\,\#FF586A_0\%\,\#FF2533_100\%\)\] {
    background-image: linear-gradient(135deg, #ff586a 0%, #ff2533 100%);
}
.bg-\[linear-gradient\(180deg\,\#C10201_-0\.73\%\,\#E40F00_99\.99\%\)\] {
    background-image: linear-gradient(180deg, #c10201 -0.73%, #e40f00 99.99%);
}
.bg-\[linear-gradient\(225deg\,\#DA1C1C_20\.56\%\,\#8C1515_86\.54\%\)\] {
    background-image: linear-gradient(225deg, #da1c1c 20.56%, #8c1515 86.54%);
}
.bg-\[linear-gradient\(249\.52deg\,\#FFBD09_-40\.84\%\,\#B6661C_64\.36\%\)\] {
    background-image: linear-gradient(249.52deg, #ffbd09 -40.84%, #b6661c 64.36%);
}
.bg-\[linear-gradient\(81deg\,\#FFA800_14\.25\%\,\#FF9400_85\.75\%\)\] {
    background-image: linear-gradient(81deg, #ffa800 14.25%, #ff9400 85.75%);
}
.bg-\[linear-gradient\(90deg\,\#FF9206_-32\.79\%\,\#FFB62E_98\.38\%\)\] {
    background-image: linear-gradient(90deg, #ff9206 -32.79%, #ffb62e 98.38%);
}
.bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
}
.bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.from-\[\#151515\] {
    --tw-gradient-from: #151515 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(21 21 21 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-\[\#FF624D\] {
    --tw-gradient-from: #ff624d var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 98 77 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-\[\#FF8C19\] {
    --tw-gradient-from: #ff8c19 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 140 25 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-\[\#FFC508_-74\.63\%\] {
    --tw-gradient-from: #ffc508 -74.63% var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.from-\[\#FFE02E_-146\.49\%\] {
    --tw-gradient-from: #ffe02e -146.49% var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.to-\[\#FF3726\] {
    --tw-gradient-to: #ff3726 var(--tw-gradient-to-position);
}
.to-\[\#FF7900\] {
    --tw-gradient-to: #ff7900 var(--tw-gradient-to-position);
}
.to-\[\#FF9E0B\] {
    --tw-gradient-to: #ff9e0b var(--tw-gradient-to-position);
}
.to-\[\#FFC423_97\.38\%\] {
    --tw-gradient-to: #ffc423 97.38% var(--tw-gradient-to-position);
}
.bg-contain {
    background-size: contain;
}
.bg-cover {
    background-size: cover;
}
.bg-fixed {
    background-attachment: fixed;
}
.bg-center {
    background-position: center;
}
.bg-right {
    background-position: right;
}
.bg-top {
    background-position: top;
}
.bg-no-repeat {
    background-repeat: no-repeat;
}
.object-contain {
    -o-object-fit: contain;
    object-fit: contain;
}
.object-cover {
    -o-object-fit: cover;
    object-fit: cover;
}
.object-bottom {
    -o-object-position: bottom;
    object-position: bottom;
}
.object-left {
    -o-object-position: left;
    object-position: left;
}
.p-0 {
    padding: 0;
}
.p-0\.5 {
    padding: 0.125rem;
}
.p-1 {
    padding: 0.25rem;
}
.p-1\.5 {
    padding: 0.375rem;
}
.p-12 {
    padding: 3rem;
}
.p-2 {
    padding: 0.5rem;
}
.p-2\.5 {
    padding: 0.625rem;
}
.p-3 {
    padding: 0.75rem;
}
.p-3\.5 {
    padding: 0.875rem;
}
.p-4 {
    padding: 1rem;
}
.p-5 {
    padding: 1.25rem;
}
.p-6 {
    padding: 1.5rem;
}
.p-8 {
    padding: 2rem;
}
.p-\[13px\] {
    padding: 13px;
}
.p-\[18\.75\%\] {
    padding: 18.75%;
}
.p-\[3px\] {
    padding: 3px;
}
.p-\[43px\] {
    padding: 43px;
}
.p-\[5px\] {
    padding: 5px;
}
.p-\[9px\] {
    padding: 9px;
}
.px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
.px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
}
.px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}
.px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
}
.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}
.px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
}
.px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
}
.px-\[11px\] {
    padding-left: 11px;
    padding-right: 11px;
}
.px-\[13px\] {
    padding-left: 13px;
    padding-right: 13px;
}
.px-\[18px\] {
    padding-left: 18px;
    padding-right: 18px;
}
.px-\[22px\] {
    padding-left: 22px;
    padding-right: 22px;
}
.px-\[3px\] {
    padding-left: 3px;
    padding-right: 3px;
}
.px-\[7px\] {
    padding-left: 7px;
    padding-right: 7px;
}
.py-0 {
    padding-top: 0;
    padding-bottom: 0;
}
.py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}
.py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}
.py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}
.py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}
.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
}
.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}
.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.py-\[15px\] {
    padding-top: 15px;
    padding-bottom: 15px;
}
.py-\[30px\] {
    padding-top: 30px;
    padding-bottom: 30px;
}
.py-\[3px\] {
    padding-top: 3px;
    padding-bottom: 3px;
}
.py-\[5px\] {
    padding-top: 5px;
    padding-bottom: 5px;
}
.py-\[7px\] {
    padding-top: 7px;
    padding-bottom: 7px;
}
.pb-0 {
    padding-bottom: 0;
}
.pb-2 {
    padding-bottom: 0.5rem;
}
.pb-4 {
    padding-bottom: 1rem;
}
.pb-6 {
    padding-bottom: 1.5rem;
}
.pb-8 {
    padding-bottom: 2rem;
}
.pb-9 {
    padding-bottom: 2.25rem;
}
.pb-\[14px\] {
    padding-bottom: 14px;
}
.pb-\[22px\] {
    padding-bottom: 22px;
}
.pb-\[88px\] {
    padding-bottom: 88px;
}
.pe-0 {
    padding-inline-end: 0px;
}
.pe-1 {
    padding-inline-end: 0.25rem;
}
.pe-11 {
    padding-inline-end: 2.75rem;
}
.pe-3 {
    padding-inline-end: 0.75rem;
}
.pe-3\.5 {
    padding-inline-end: 0.875rem;
}
.pe-\[11px\] {
    padding-inline-end: 11px;
}
.ps-1 {
    padding-inline-start: 0.25rem;
}
.ps-16 {
    padding-inline-start: 4rem;
}
.ps-4 {
    padding-inline-start: 1rem;
}
.ps-\[38px\] {
    padding-inline-start: 38px;
}
.pt-1 {
    padding-top: 0.25rem;
}
.pt-14 {
    padding-top: 3.5rem;
}
.pt-4 {
    padding-top: 1rem;
}
.pt-5 {
    padding-top: 1.25rem;
}
.pt-8 {
    padding-top: 2rem;
}
.pt-9 {
    padding-top: 2.25rem;
}
.pt-\[100\%\] {
    padding-top: 100%;
}
.pt-\[28\.048\%\] {
    padding-top: 28.048%;
}
.pt-\[43\.478\%\] {
    padding-top: 43.478%;
}
.pt-\[52px\] {
    padding-top: 52px;
}
.pt-\[56\.25\%\] {
    padding-top: 56.25%;
}
.pt-\[68px\] {
    padding-top: 68px;
}
.pt-\[79\.8\%\] {
    padding-top: 79.8%;
}
.text-center {
    text-align: center;
}
.text-start {
    text-align: start;
}
.text-end {
    text-align: end;
}
.align-middle {
    vertical-align: middle;
}
.align-bottom {
    vertical-align: bottom;
}
.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}
.text-2xl\/none {
    font-size: 1.5rem;
    line-height: 1;
}
.text-2xs {
    font-size: 0.625rem;
    line-height: 0.75rem;
}
.text-2xs\/none {
    font-size: 0.625rem;
    line-height: 1;
}
.text-3xl\/none {
    font-size: 1.875rem;
    line-height: 1;
}
.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
}
.text-4xl\/\[1\.2\] {
    font-size: 2.25rem;
    line-height: 1.2;
}
.text-\[15px\]\/4 {
    font-size: 15px;
    line-height: 1rem;
}
.text-\[15px\]\/\[22px\] {
    font-size: 15px;
    line-height: 22px;
}
.text-\[28px\] {
    font-size: 28px;
}
.text-\[40px\] {
    font-size: 40px;
}
.text-\[90px\] {
    font-size: 90px;
}
.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}
.text-base\/none {
    font-size: 1rem;
    line-height: 1;
}
.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.text-lg\/none {
    font-size: 1.125rem;
    line-height: 1;
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.text-sm\/\[18px\] {
    font-size: 0.875rem;
    line-height: 18px;
}
.text-sm\/\[22px\] {
    font-size: 0.875rem;
    line-height: 22px;
}
.text-sm\/none {
    font-size: 0.875rem;
    line-height: 1;
}
.text-sm\/normal {
    font-size: 0.875rem;
    line-height: 1.5;
}
.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.text-xl\/6 {
    font-size: 1.25rem;
    line-height: 1.5rem;
}
.text-xl\/\[1\.2\] {
    font-size: 1.25rem;
    line-height: 1.2;
}
.text-xl\/none {
    font-size: 1.25rem;
    line-height: 1;
}
.text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}
.text-xs\/6 {
    font-size: 0.75rem;
    line-height: 1.5rem;
}
.text-xs\/none {
    font-size: 0.75rem;
    line-height: 1;
}
.text-xs\/normal {
    font-size: 0.75rem;
    line-height: 1.5;
}
.font-black {
    font-weight: 900;
}
.font-bold {
    font-weight: 700;
}
.font-medium {
    font-weight: 500;
}
.font-normal {
    font-weight: 400;
}
.font-semibold {
    font-weight: 600;
}
.uppercase {
    text-transform: uppercase;
}
.italic {
    font-style: italic;
}
.leading-5 {
    line-height: 1.25rem;
}
.leading-6 {
    line-height: 1.5rem;
}
.leading-\[30px\] {
    line-height: 30px;
}
.leading-none {
    line-height: 1;
}
.text-\[\#3A96ED\] {
    --tw-text-opacity: 1;
    color: rgb(58 150 237 / var(--tw-text-opacity));
}
.text-\[\#804B00\] {
    --tw-text-opacity: 1;
    color: rgb(128 75 0 / var(--tw-text-opacity));
}
.text-\[\#80523B\] {
    --tw-text-opacity: 1;
    color: rgb(128 82 59 / var(--tw-text-opacity));
}
.text-\[\#873E05\] {
    --tw-text-opacity: 1;
    color: rgb(135 62 5 / var(--tw-text-opacity));
}
.text-\[\#939393\] {
    --tw-text-opacity: 1;
    color: rgb(147 147 147 / var(--tw-text-opacity));
}
.text-\[\#A0A0A0\] {
    --tw-text-opacity: 1;
    color: rgb(160 160 160 / var(--tw-text-opacity));
}
.text-\[\#AA1B00\] {
    --tw-text-opacity: 1;
    color: rgb(170 27 0 / var(--tw-text-opacity));
}
.text-\[\#CECECE\] {
    --tw-text-opacity: 1;
    color: rgb(206 206 206 / var(--tw-text-opacity));
}
.text-\[\#E97E00\] {
    --tw-text-opacity: 1;
    color: rgb(233 126 0 / var(--tw-text-opacity));
}
.text-\[\#FF2533\] {
    --tw-text-opacity: 1;
    color: rgb(255 37 51 / var(--tw-text-opacity));
}
.text-\[\#FFDB1F\] {
    --tw-text-opacity: 1;
    color: rgb(255 219 31 / var(--tw-text-opacity));
}
.text-black {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}
.text-black\/50 {
    color: rgba(0, 0, 0, 0.5);
}
.text-bonus {
    --tw-text-opacity: 1;
    color: rgb(255 122 0 / var(--tw-text-opacity));
}
.text-dark-bg\/75 {
    color: rgba(0, 8, 30, 0.75);
}
.text-primary-red {
    --tw-text-opacity: 1;
    color: rgb(216 26 13 / var(--tw-text-opacity));
}
.text-red-600 {
    --tw-text-opacity: 1;
    color: rgb(220 38 38 / var(--tw-text-opacity));
}
.text-success {
    --tw-text-opacity: 1;
    color: rgb(51 178 94 / var(--tw-text-opacity));
}
.text-text-hint {
    --tw-text-opacity: 1;
    color: rgb(201 201 201 / var(--tw-text-opacity));
}
.text-text-primary {
    --tw-text-opacity: 1;
    color: rgb(50 50 50 / var(--tw-text-opacity));
}
.text-text-secondary {
    --tw-text-opacity: 1;
    color: rgb(117 117 117 / var(--tw-text-opacity));
}
.text-text-title {
    --tw-text-opacity: 1;
    color: rgb(40 41 47 / var(--tw-text-opacity));
}
.text-theme-light-gray {
    --tw-text-opacity: 1;
    color: rgb(198 198 198 / var(--tw-text-opacity));
}
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-white\/70 {
    color: rgba(255, 255, 255, 0.7);
}
.underline {
    text-decoration-line: underline;
}
.line-through {
    text-decoration-line: line-through;
}
.opacity-0 {
    opacity: 0;
}
.opacity-100 {
    opacity: 1;
}
.opacity-40 {
    opacity: 0.4;
}
.opacity-50 {
    opacity: 0.5;
}
.opacity-\[\.45\] {
    opacity: 0.45;
}
.opacity-\[0\.63\] {
    opacity: 0.63;
}
.mix-blend-overlay {
    mix-blend-mode: overlay;
}
.shadow-\[0px_4px_20px_0px_rgba\(0\,0\,0\,0\.10\)\] {
    --tw-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
    --tw-shadow-colored: 0px 4px 20px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[0px_9px_28px_8px_rgba\(0\,0\,0\,0\.05\)\] {
    --tw-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 9px 28px 8px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-top-line {
    --tw-shadow: inset 0px 0.5px 0px #eeeeee;
    --tw-shadow-colored: inset 0px 0.5px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.outline {
    outline-style: solid;
}
.outline-1 {
    outline-width: 1px;
}
.outline-2 {
    outline-width: 2px;
}
.outline-4 {
    outline-width: 4px;
}
.outline-\[0\.5px\] {
    outline-width: 0.5px;
}
.-outline-offset-1 {
    outline-offset: -1px;
}
.-outline-offset-2 {
    outline-offset: -2px;
}
.-outline-offset-\[0\.5px\] {
    outline-offset: -0.5px;
}
.outline-box-border {
    outline-color: #d3d3d3;
}
.outline-current {
    outline-color: currentColor;
}
.outline-line {
    outline-color: #eee;
}
.outline-primary-red {
    outline-color: #d81a0d;
}
.outline-title-line {
    outline-color: #dfdfdf;
}
.outline-white {
    outline-color: #fff;
}
.outline-white\/50 {
    outline-color: rgba(255, 255, 255, 0.5);
}
.brightness-0 {
    --tw-brightness: brightness(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
        var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.grayscale {
    --tw-grayscale: grayscale(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
        var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.invert {
    --tw-invert: invert(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
        var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
        var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
        -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
        backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
        backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
}
.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
}
.transition-colors {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
}
.transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
}
.transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
}
.duration-150 {
    transition-duration: 0.15s;
}
.duration-200 {
    transition-duration: 0.2s;
}
.duration-500 {
    transition-duration: 0.5s;
}
.ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-linear {
    transition-timing-function: linear;
}
.ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.scrollbar-none {
    scrollbar-width: none;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
.scrollbar-none::-webkit-scrollbar {
    display: none;
}
.link-inside a {
    color: #5e7fb2;
}
.scroll-shadow-bottom {
    background: linear-gradient(transparent, white 50%) center bottom, linear-gradient(transparent, rgba(0, 0, 0, 0.04)) center bottom;
    background-repeat: no-repeat;
    background-size: 100% 24px, 100% 12px;
    background-attachment: local, scroll;
}
.dark .scroll-shadow-bottom {
    background: linear-gradient(transparent, #282938 50%) center bottom, linear-gradient(transparent, rgba(0, 0, 0, 0.2)) center bottom;
    background-repeat: no-repeat;
    background-size: 100% 24px, 100% 12px;
    background-attachment: local, scroll;
}
.\[text-decoration\:inherit\] {
    text-decoration: inherit;
}
.tos-pp-wrap {
    margin-top: 0.625rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
}
@media (min-width: 768px) {
    .tos-pp-wrap {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}
.tos-pp-wrap h1 {
    font-size: 1rem;
    line-height: 1.5rem;
}
@media (min-width: 768px) {
    .tos-pp-wrap h1 {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}
.tos-pp-wrap em {
    font-style: italic;
}
.tos-pp-wrap strong {
    font-weight: 700;
}
.tos-pp-wrap a {
    --tw-text-opacity: 1;
    color: rgb(94 127 178 / var(--tw-text-opacity));
}
.tos-pp-wrap a:hover {
    text-decoration-line: underline;
}
.tos-pp-wrap ol {
    counter-reset: item;
}
.tos-pp-wrap ol li {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
    display: table;
}
.tos-pp-wrap ol li ol li:before {
    display: table-cell;
    width: 2.5rem;
}
@media (min-width: 768px) {
    .tos-pp-wrap ol li ol li:before {
        width: 4rem;
    }
}
.tos-pp-wrap ol li ol li ol li:before {
    content: '(' counter(item, lower-alpha) ')';
    display: table-cell;
    width: 2.5rem;
}
@media (min-width: 768px) {
    .tos-pp-wrap ol li ol li ol li:before {
        width: 4rem;
    }
}
.tos-pp-wrap ol li:before {
    counter-increment: item;
    content: counters(item, '.') '. ';
    display: inline-block;
    width: 2.5rem;
}
@media (min-width: 768px) {
    .tos-pp-wrap ol li:before {
        width: 4rem;
    }
}
.tos-pp-wrap ol p {
    margin-top: 0.875rem;
}
.placeholder\:text-text-hint::-moz-placeholder {
    --tw-text-opacity: 1;
    color: rgb(201 201 201 / var(--tw-text-opacity));
}
.placeholder\:text-text-hint::placeholder {
    --tw-text-opacity: 1;
    color: rgb(201 201 201 / var(--tw-text-opacity));
}
.before\:h-px:before {
    content: var(--tw-content);
    height: 1px;
}
.before\:grow:before {
    content: var(--tw-content);
    flex-grow: 1;
}
.before\:bg-line:before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(238 238 238 / var(--tw-bg-opacity));
}
.after\:absolute:after {
    content: var(--tw-content);
    position: absolute;
}
.after\:inset-x-0:after {
    content: var(--tw-content);
    left: 0;
    right: 0;
}
.after\:bottom-0:after {
    content: var(--tw-content);
    bottom: 0;
}
.after\:mx-1:after {
    content: var(--tw-content);
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}
.after\:block:after {
    content: var(--tw-content);
    display: block;
}
.after\:h-\[3px\]:after {
    content: var(--tw-content);
    height: 3px;
}
.after\:h-px:after {
    content: var(--tw-content);
    height: 1px;
}
.after\:grow:after {
    content: var(--tw-content);
    flex-grow: 1;
}
.after\:rounded-full:after {
    content: var(--tw-content);
    border-radius: 9999px;
}
.after\:bg-line:after {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(238 238 238 / var(--tw-bg-opacity));
}
.after\:bg-primary-red:after {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(216 26 13 / var(--tw-bg-opacity));
}
.after\:transition-colors:after {
    content: var(--tw-content);
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;
}
.first\:pt-0:first-child {
    padding-top: 0;
}
.first\:shadow-none:first-child {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.last\:items-end:last-child {
    align-items: flex-end;
}
.empty\:hidden:empty {
    display: none;
}
.hover\:border-box-border-focus:hover {
    --tw-border-opacity: 1;
    border-color: rgb(153 153 159 / var(--tw-border-opacity));
}
.hover\:border-primary-red-hover:hover {
    --tw-border-opacity: 1;
    border-color: rgb(205 18 20 / var(--tw-border-opacity));
}
.hover\:bg-\[\#606060\]\/50:hover {
    background-color: rgba(96, 96, 96, 0.5);
}
.hover\:bg-\[\#FAFAFA\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}
.hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
.hover\:bg-primary-red-hover:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(205 18 20 / var(--tw-bg-opacity));
}
.hover\:bg-white-hover:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(254 245 244 / var(--tw-bg-opacity));
}
.hover\:text-primary-red-hover:hover {
    --tw-text-opacity: 1;
    color: rgb(205 18 20 / var(--tw-text-opacity));
}
.hover\:text-text-secondary:hover {
    --tw-text-opacity: 1;
    color: rgb(117 117 117 / var(--tw-text-opacity));
}
.hover\:opacity-70:hover {
    opacity: 0.7;
}
.hover\:opacity-80:hover {
    opacity: 0.8;
}
.focus-visible\:border-box-border-focus:focus-visible {
    --tw-border-opacity: 1;
    border-color: rgb(153 153 159 / var(--tw-border-opacity));
}
.focus-visible\:font-medium:focus-visible {
    font-weight: 500;
}
.focus-visible\:outline:focus-visible {
    outline-style: solid;
}
.focus-visible\:outline-2:focus-visible {
    outline-width: 2px;
}
.focus-visible\:-outline-offset-2:focus-visible {
    outline-offset: -2px;
}
.focus-visible\:outline-box-border-focus:focus-visible {
    outline-color: #99999f;
}
.active\:opacity-60:active {
    opacity: 0.6;
}
.disabled\:pointer-events-none:disabled {
    pointer-events: none;
}
.disabled\:text-text-hint:disabled {
    --tw-text-opacity: 1;
    color: rgb(201 201 201 / var(--tw-text-opacity));
}
.disabled\:opacity-40:disabled {
    opacity: 0.4;
}
.disabled\:grayscale:disabled {
    --tw-grayscale: grayscale(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
        var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.group.loading .group-\[\.loading\]\:pointer-events-none {
    pointer-events: none;
}
.group.loading .group-\[\.loading\]\:opacity-50 {
    opacity: 0.5;
}
.aria-checked\:cursor-pointer[aria-checked='true'] {
    cursor: pointer;
}
.aria-checked\:bg-\[\#FFFAEC\][aria-checked='true'] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 250 236 / var(--tw-bg-opacity));
}
.aria-checked\:bg-primary-red[aria-checked='true'] {
    --tw-bg-opacity: 1;
    background-color: rgb(216 26 13 / var(--tw-bg-opacity));
}
.group[aria-checked='true'] .group-aria-checked\:w-28 {
    width: 7rem;
}
.group[aria-checked='true'] .group-aria-checked\:bg-\[\#FFEEC0\]\/60 {
    background-color: rgba(255, 238, 192, 0.6);
}
.peer[aria-checked='true'] ~ .peer-aria-checked\:block {
    display: block;
}
.ui-checked\:border-primary-red[data-headlessui-state~='checked'] {
    --tw-border-opacity: 1;
    border-color: rgb(216 26 13 / var(--tw-border-opacity));
}
.ui-checked\:bg-selected-bg[data-headlessui-state~='checked'] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 250 250 / var(--tw-bg-opacity));
}
.ui-checked\:font-bold[data-headlessui-state~='checked'] {
    font-weight: 700;
}
.ui-checked\:text-primary-red[data-headlessui-state~='checked'] {
    --tw-text-opacity: 1;
    color: rgb(216 26 13 / var(--tw-text-opacity));
}
.ui-checked\:opacity-100[data-headlessui-state~='checked'] {
    opacity: 1;
}
.ui-checked\:outline-2[data-headlessui-state~='checked'] {
    outline-width: 2px;
}
.ui-checked\:-outline-offset-2[data-headlessui-state~='checked'] {
    outline-offset: -2px;
}
.ui-checked\:outline-primary-red[data-headlessui-state~='checked'] {
    outline-color: #d81a0d;
}
:where([data-headlessui-state~='checked']) .ui-checked\:border-primary-red {
    --tw-border-opacity: 1;
    border-color: rgb(216 26 13 / var(--tw-border-opacity));
}
:where([data-headlessui-state~='checked']) .ui-checked\:bg-selected-bg {
    --tw-bg-opacity: 1;
    background-color: rgb(255 250 250 / var(--tw-bg-opacity));
}
:where([data-headlessui-state~='checked']) .ui-checked\:font-bold {
    font-weight: 700;
}
:where([data-headlessui-state~='checked']) .ui-checked\:text-primary-red {
    --tw-text-opacity: 1;
    color: rgb(216 26 13 / var(--tw-text-opacity));
}
:where([data-headlessui-state~='checked']) .ui-checked\:opacity-100 {
    opacity: 1;
}
:where([data-headlessui-state~='checked']) .ui-checked\:outline-2 {
    outline-width: 2px;
}
:where([data-headlessui-state~='checked']) .ui-checked\:-outline-offset-2 {
    outline-offset: -2px;
}
:where([data-headlessui-state~='checked']) .ui-checked\:outline-primary-red {
    outline-color: #d81a0d;
}
.ui-selected\:bg-white[data-headlessui-state~='selected'] {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.ui-selected\:font-bold[data-headlessui-state~='selected'] {
    font-weight: 700;
}
.ui-selected\:text-primary-red[data-headlessui-state~='selected'] {
    --tw-text-opacity: 1;
    color: rgb(216 26 13 / var(--tw-text-opacity));
}
:where([data-headlessui-state~='selected']) .ui-selected\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
:where([data-headlessui-state~='selected']) .ui-selected\:font-bold {
    font-weight: 700;
}
:where([data-headlessui-state~='selected']) .ui-selected\:text-primary-red {
    --tw-text-opacity: 1;
    color: rgb(216 26 13 / var(--tw-text-opacity));
}
.ui-disabled\:cursor-not-allowed[data-headlessui-state~='disabled'] {
    cursor: not-allowed;
}
.ui-disabled\:bg-\[\#F4F4F4\][data-headlessui-state~='disabled'] {
    --tw-bg-opacity: 1;
    background-color: rgb(244 244 244 / var(--tw-bg-opacity));
}
.ui-disabled\:outline-none[data-headlessui-state~='disabled'] {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.ui-disabled\:grayscale[data-headlessui-state~='disabled'] {
    --tw-grayscale: grayscale(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
        var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
:where([data-headlessui-state~='disabled']) .ui-disabled\:cursor-not-allowed {
    cursor: not-allowed;
}
:where([data-headlessui-state~='disabled']) .ui-disabled\:bg-\[\#F4F4F4\] {
    --tw-bg-opacity: 1;
    background-color: rgb(244 244 244 / var(--tw-bg-opacity));
}
:where([data-headlessui-state~='disabled']) .ui-disabled\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}
:where([data-headlessui-state~='disabled']) .ui-disabled\:grayscale {
    --tw-grayscale: grayscale(100%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
        var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
:is([dir='ltr'] .ltr\:rotate-180) {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
:is([dir='ltr'] .ltr\:rounded-r-none) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
:is([dir='ltr'] .ltr\:border-r-0) {
    border-right-width: 0px;
}
:is([dir='ltr'] .ltr\:border-l-transparent) {
    border-left-color: transparent;
}
:is([dir='ltr'] .ltr\:bg-\[linear-gradient\(-45deg\,transparent_50\%\,\#D81A0D_50\%\)\]) {
    background-image: linear-gradient(-45deg, transparent 50%, #d81a0d 50%);
}
:is([dir='ltr'] .ltr\:pr-\[46px\]) {
    padding-right: 46px;
}
:is([dir='rtl'] .rtl\:origin-top-left) {
    transform-origin: top left;
}
:is([dir='rtl'] .rtl\:origin-top-right) {
    transform-origin: top right;
}
:is([dir='rtl'] .rtl\:-translate-x-full) {
    --tw-translate-x: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
:is([dir='rtl'] .rtl\:translate-x-1\/2) {
    --tw-translate-x: 50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
:is([dir='rtl'] .rtl\:translate-x-full) {
    --tw-translate-x: 100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
:is([dir='rtl'] .rtl\:-rotate-180) {
    --tw-rotate: -180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
:is([dir='rtl'] .rtl\:-rotate-90) {
    --tw-rotate: -90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
:is([dir='rtl'] .rtl\:rotate-180) {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
:is([dir='rtl'] .rtl\:rotate-90) {
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
:is([dir='rtl'] .rtl\:-scale-x-100) {
    --tw-scale-x: -1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
:is([dir='rtl'] .rtl\:scale-x-100) {
    --tw-scale-x: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
        skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
:is([dir='rtl'] .rtl\:flex-row-reverse) {
    flex-direction: row-reverse;
}
:is([dir='rtl'] .rtl\:rounded-l-none) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
:is([dir='rtl'] .rtl\:border-l-0) {
    border-left-width: 0px;
}
:is([dir='rtl'] .rtl\:border-r-transparent) {
    border-right-color: transparent;
}
:is([dir='rtl'] .rtl\:bg-\[linear-gradient\(-105deg\,\#FF586A_0\%\,\#FF2533_100\%\)\]) {
    background-image: linear-gradient(-105deg, #ff586a 0%, #ff2533 100%);
}
:is([dir='rtl'] .rtl\:bg-\[linear-gradient\(-135deg\,\#FF586A_0\%\,\#FF2533_100\%\)\]) {
    background-image: linear-gradient(-135deg, #ff586a 0%, #ff2533 100%);
}
:is([dir='rtl'] .rtl\:bg-\[linear-gradient\(-249\.52deg\,\#FFBD09_-40\.84\%\,\#B6661C_64\.36\%\)\]) {
    background-image: linear-gradient(-249.52deg, #ffbd09 -40.84%, #b6661c 64.36%);
}
:is([dir='rtl'] .rtl\:bg-\[linear-gradient\(-81deg\,\#FFA800_14\.25\%\,\#FF9400_85\.75\%\)\]) {
    background-image: linear-gradient(-81deg, #ffa800 14.25%, #ff9400 85.75%);
}
:is([dir='rtl'] .rtl\:bg-\[linear-gradient\(45deg\,transparent_50\%\,\#D81A0D_50\%\)\]) {
    background-image: linear-gradient(45deg, transparent 50%, #d81a0d 50%);
}
:is([dir='rtl'] .rtl\:bg-gradient-to-l) {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
}
:is([dir='rtl'] .rtl\:bg-gradient-to-r) {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
:is([dir='rtl'] .rtl\:object-right) {
    -o-object-position: right;
    object-position: right;
}
:is([dir='rtl'] .rtl\:pl-\[46px\]) {
    padding-left: 46px;
}
:is(.dark .dark\:border-t-neutral-200) {
    --tw-border-opacity: 1;
    border-top-color: rgb(229 229 229 / var(--tw-border-opacity));
}
:is(.dark .dark\:bg-amber-400) {
    --tw-bg-opacity: 1;
    background-color: rgb(251 191 36 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-neutral-200) {
    --tw-bg-opacity: 1;
    background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-slate-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-theme-dark) {
    --tw-bg-opacity: 1;
    background-color: rgb(40 41 56 / var(--tw-bg-opacity));
}
:is(.dark .dark\:text-amber-400) {
    --tw-text-opacity: 1;
    color: rgb(251 191 36 / var(--tw-text-opacity));
}
:is(.dark .dark\:text-black) {
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
}
@media not all and (min-width: 768px) {
    .max-md\:inset-x-0 {
        left: 0;
        right: 0;
    }
    .max-md\:inset-y-0 {
        top: 0;
        bottom: 0;
    }
    .max-md\:start-4 {
        inset-inline-start: 1rem;
    }
    .max-md\:mx-4 {
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .max-md\:mt-2 {
        margin-top: 0.5rem;
    }
    .max-md\:hidden {
        display: none;
    }
    .max-md\:h-full {
        height: 100%;
    }
    .max-md\:max-w-24 {
        max-width: 6rem;
    }
    .max-md\:max-w-40 {
        max-width: 10rem;
    }
    .max-md\:shrink {
        flex-shrink: 1;
    }
    .max-md\:grow {
        flex-grow: 1;
    }
    .max-md\:flex-col {
        flex-direction: column;
    }
    .max-md\:justify-center {
        justify-content: center;
    }
    .max-md\:gap-y-\[5px\] {
        row-gap: 5px;
    }
    .max-md\:border-b {
        border-bottom-width: 1px;
    }
    .max-md\:border-line\/50 {
        border-color: rgba(238, 238, 238, 0.5);
    }
    .max-md\:border-b-line\/50 {
        border-bottom-color: rgba(238, 238, 238, 0.5);
    }
    .max-md\:border-b-title-line\/50 {
        border-bottom-color: rgba(223, 223, 223, 0.5);
    }
    .max-md\:px-4 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .max-md\:pb-5 {
        padding-bottom: 1.25rem;
    }
}
@media (min-width: 640px) {
    .sm\:h-\[650px\] {
        height: 650px;
    }
    .sm\:min-h-\[64px\] {
        min-height: 64px;
    }
    .sm\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .sm\:grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    @media not all and (min-width: 768px) {
        .sm\:max-md\:object-contain {
            -o-object-fit: contain;
            object-fit: contain;
        }
    }
}
@media (min-width: 768px) {
    .md\:absolute {
        position: absolute;
    }
    .md\:inset-x-0 {
        left: 0;
        right: 0;
    }
    .md\:-end-4 {
        inset-inline-end: -1rem;
    }
    .md\:-top-3 {
        top: -0.75rem;
    }
    .md\:bottom-6 {
        bottom: 1.5rem;
    }
    .md\:end-3 {
        inset-inline-end: 0.75rem;
    }
    .md\:start-1 {
        inset-inline-start: 0.25rem;
    }
    .md\:start-1\.5 {
        inset-inline-start: 0.375rem;
    }
    .md\:start-6 {
        inset-inline-start: 1.5rem;
    }
    .md\:top-1 {
        top: 0.25rem;
    }
    .md\:top-1\.5 {
        top: 0.375rem;
    }
    .md\:top-10 {
        top: 2.5rem;
    }
    .md\:top-3 {
        top: 0.75rem;
    }
    .md\:top-6 {
        top: 1.5rem;
    }
    .md\:top-\[60px\] {
        top: 60px;
    }
    .md\:order-1 {
        order: 1;
    }
    .md\:m-5 {
        margin: 1.25rem;
    }
    .md\:mx-0 {
        margin-left: 0;
        margin-right: 0;
    }
    .md\:mx-3 {
        margin-left: 0.75rem;
        margin-right: 0.75rem;
    }
    .md\:mx-3\.5 {
        margin-left: 0.875rem;
        margin-right: 0.875rem;
    }
    .md\:mx-auto {
        margin-left: auto;
        margin-right: auto;
    }
    .md\:-mt-10 {
        margin-top: -2.5rem;
    }
    .md\:mb-0 {
        margin-bottom: 0;
    }
    .md\:mb-1 {
        margin-bottom: 0.25rem;
    }
    .md\:mb-1\.5 {
        margin-bottom: 0.375rem;
    }
    .md\:mb-10 {
        margin-bottom: 2.5rem;
    }
    .md\:mb-12 {
        margin-bottom: 3rem;
    }
    .md\:mb-2 {
        margin-bottom: 0.5rem;
    }
    .md\:mb-3 {
        margin-bottom: 0.75rem;
    }
    .md\:mb-3\.5 {
        margin-bottom: 0.875rem;
    }
    .md\:mb-4 {
        margin-bottom: 1rem;
    }
    .md\:mb-5 {
        margin-bottom: 1.25rem;
    }
    .md\:mb-6 {
        margin-bottom: 1.5rem;
    }
    .md\:mb-8 {
        margin-bottom: 2rem;
    }
    .md\:mb-\[52px\] {
        margin-bottom: 52px;
    }
    .md\:ms-0 {
        margin-inline-start: 0px;
    }
    .md\:ms-3 {
        margin-inline-start: 0.75rem;
    }
    .md\:ms-3\.5 {
        margin-inline-start: 0.875rem;
    }
    .md\:mt-0 {
        margin-top: 0;
    }
    .md\:mt-4 {
        margin-top: 1rem;
    }
    .md\:mt-6 {
        margin-top: 1.5rem;
    }
    .md\:block {
        display: block;
    }
    .md\:flex {
        display: flex;
    }
    .md\:hidden {
        display: none;
    }
    .md\:h-10 {
        height: 2.5rem;
    }
    .md\:h-14 {
        height: 3.5rem;
    }
    .md\:h-2 {
        height: 0.5rem;
    }
    .md\:h-2\.5 {
        height: 0.625rem;
    }
    .md\:h-20 {
        height: 5rem;
    }
    .md\:h-3 {
        height: 0.75rem;
    }
    .md\:h-3\.5 {
        height: 0.875rem;
    }
    .md\:h-4 {
        height: 1rem;
    }
    .md\:h-5 {
        height: 1.25rem;
    }
    .md\:h-52 {
        height: 13rem;
    }
    .md\:h-6 {
        height: 1.5rem;
    }
    .md\:h-8 {
        height: 2rem;
    }
    .md\:h-\[120px\] {
        height: 120px;
    }
    .md\:h-\[130px\] {
        height: 130px;
    }
    .md\:h-\[350px\] {
        height: 350px;
    }
    .md\:h-\[60px\] {
        height: 60px;
    }
    .md\:h-auto {
        height: auto;
    }
    .md\:max-h-\[318px\] {
        max-height: 318px;
    }
    .md\:max-h-\[392px\] {
        max-height: 392px;
    }
    .md\:min-h-\[450px\] {
        min-height: 450px;
    }
    .md\:min-h-\[72px\] {
        min-height: 72px;
    }
    .md\:min-h-full {
        min-height: 100%;
    }
    .md\:w-12 {
        width: 3rem;
    }
    .md\:w-14 {
        width: 3.5rem;
    }
    .md\:w-2 {
        width: 0.5rem;
    }
    .md\:w-2\.5 {
        width: 0.625rem;
    }
    .md\:w-20 {
        width: 5rem;
    }
    .md\:w-4 {
        width: 1rem;
    }
    .md\:w-5 {
        width: 1.25rem;
    }
    .md\:w-\[140px\] {
        width: 140px;
    }
    .md\:w-\[152px\] {
        width: 152px;
    }
    .md\:w-\[350px\] {
        width: 350px;
    }
    .md\:w-\[390px\] {
        width: 390px;
    }
    .md\:w-\[400px\] {
        width: 400px;
    }
    .md\:w-\[50\.577\%\] {
        width: 50.577%;
    }
    .md\:w-\[720px\] {
        width: 720px;
    }
    .md\:w-full {
        width: 100%;
    }
    .md\:max-w-1\/2 {
        max-width: 50%;
    }
    .md\:max-w-5xl {
        max-width: 64rem;
    }
    .md\:max-w-60 {
        max-width: 15rem;
    }
    .md\:max-w-80 {
        max-width: 20rem;
    }
    .md\:max-w-\[105px\] {
        max-width: 105px;
    }
    .md\:max-w-\[464px\] {
        max-width: 464px;
    }
    .md\:max-w-\[600px\] {
        max-width: 600px;
    }
    .md\:max-w-lg {
        max-width: 32rem;
    }
    .md\:max-w-sm {
        max-width: 24rem;
    }
    .md\:origin-bottom {
        transform-origin: bottom;
    }
    .md\:-translate-y-\[calc\(100\%\+52px\)\] {
        --tw-translate-y: calc((100% + 52px) * -1);
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
            skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    .md\:scale-\[45\.714\%\] {
        --tw-scale-x: 45.714%;
        --tw-scale-y: 45.714%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
            skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    .md\:scale-\[94\.211\%\] {
        --tw-scale-x: 94.211%;
        --tw-scale-y: 94.211%;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x))
            skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    }
    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .md\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .md\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    .md\:grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
    .md\:flex-row {
        flex-direction: row;
    }
    .md\:flex-col {
        flex-direction: column;
    }
    .md\:items-start {
        align-items: flex-start;
    }
    .md\:items-end {
        align-items: flex-end;
    }
    .md\:items-center {
        align-items: center;
    }
    .md\:justify-end {
        justify-content: flex-end;
    }
    .md\:justify-center {
        justify-content: center;
    }
    .md\:justify-between {
        justify-content: space-between;
    }
    .md\:gap-1 {
        gap: 0.25rem;
    }
    .md\:gap-1\.5 {
        gap: 0.375rem;
    }
    .md\:gap-10 {
        gap: 2.5rem;
    }
    .md\:gap-2 {
        gap: 0.5rem;
    }
    .md\:gap-2\.5 {
        gap: 0.625rem;
    }
    .md\:gap-3 {
        gap: 0.75rem;
    }
    .md\:gap-4 {
        gap: 1rem;
    }
    .md\:gap-5 {
        gap: 1.25rem;
    }
    .md\:gap-6 {
        gap: 1.5rem;
    }
    .md\:gap-\[18px\] {
        gap: 18px;
    }
    .md\:gap-x-1 {
        -moz-column-gap: 0.25rem;
        column-gap: 0.25rem;
    }
    .md\:gap-x-1\.5 {
        -moz-column-gap: 0.375rem;
        column-gap: 0.375rem;
    }
    .md\:gap-x-\[30px\] {
        -moz-column-gap: 30px;
        column-gap: 30px;
    }
    .md\:gap-y-2 {
        row-gap: 0.5rem;
    }
    .md\:self-end {
        align-self: flex-end;
    }
    .md\:overflow-hidden {
        overflow: hidden;
    }
    .md\:overflow-y-auto {
        overflow-y: auto;
    }
    .md\:rounded-2xl {
        border-radius: 1rem;
    }
    .md\:rounded-\[5px\] {
        border-radius: 5px;
    }
    .md\:rounded-lg {
        border-radius: 0.5rem;
    }
    .md\:rounded-md {
        border-radius: 0.375rem;
    }
    .md\:rounded-sm {
        border-radius: 0.125rem;
    }
    .md\:rounded-xl {
        border-radius: 0.75rem;
    }
    .md\:rounded-b-lg {
        border-bottom-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
    }
    .md\:rounded-t-2xl {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }
    .md\:rounded-t-lg {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    }
    .md\:border {
        border-width: 1px;
    }
    .md\:border-4 {
        border-width: 4px;
    }
    .md\:border-t {
        border-top-width: 1px;
    }
    .md\:border-none {
        border-style: none;
    }
    .md\:border-line {
        --tw-border-opacity: 1;
        border-color: rgb(238 238 238 / var(--tw-border-opacity));
    }
    .md\:border-title-line\/50 {
        border-color: rgba(223, 223, 223, 0.5);
    }
    .md\:border-s-white {
        --tw-border-opacity: 1;
        border-inline-start-color: rgb(255 255 255 / var(--tw-border-opacity));
    }
    .md\:border-t-white {
        --tw-border-opacity: 1;
        border-top-color: rgb(255 255 255 / var(--tw-border-opacity));
    }
    .md\:bg-\[\#F5F5F5\] {
        --tw-bg-opacity: 1;
        background-color: rgb(245 245 245 / var(--tw-bg-opacity));
    }
    .md\:bg-\[\#F9F9F9\] {
        --tw-bg-opacity: 1;
        background-color: rgb(249 249 249 / var(--tw-bg-opacity));
    }
    .md\:bg-\[\#FAFAFA\] {
        --tw-bg-opacity: 1;
        background-color: rgb(250 250 250 / var(--tw-bg-opacity));
    }
    .md\:bg-transparent {
        background-color: transparent;
    }
    .md\:bg-white {
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    }
    .md\:bg-white\/40 {
        background-color: rgba(255, 255, 255, 0.4);
    }
    .md\:bg-contain {
        background-size: contain;
    }
    .md\:bg-center {
        background-position: center;
    }
    .md\:p-1 {
        padding: 0.25rem;
    }
    .md\:p-1\.5 {
        padding: 0.375rem;
    }
    .md\:p-10 {
        padding: 2.5rem;
    }
    .md\:p-2 {
        padding: 0.5rem;
    }
    .md\:p-3 {
        padding: 0.75rem;
    }
    .md\:p-3\.5 {
        padding: 0.875rem;
    }
    .md\:p-4 {
        padding: 1rem;
    }
    .md\:p-6 {
        padding: 1.5rem;
    }
    .md\:p-8 {
        padding: 2rem;
    }
    .md\:p-\[15px\] {
        padding: 15px;
    }
    .md\:p-\[30px\] {
        padding: 30px;
    }
    .md\:p-\[62px\] {
        padding: 62px;
    }
    .md\:px-10 {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
    .md\:px-2 {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .md\:px-3 {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }
    .md\:px-3\.5 {
        padding-left: 0.875rem;
        padding-right: 0.875rem;
    }
    .md\:px-4 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .md\:px-6 {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .md\:px-8 {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .md\:px-\[45px\] {
        padding-left: 45px;
        padding-right: 45px;
    }
    .md\:px-\[55px\] {
        padding-left: 55px;
        padding-right: 55px;
    }
    .md\:py-0 {
        padding-top: 0;
        padding-bottom: 0;
    }
    .md\:py-1 {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }
    .md\:py-1\.5 {
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
    }
    .md\:py-2 {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .md\:py-2\.5 {
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
    }
    .md\:py-24 {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    .md\:py-3 {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }
    .md\:py-6 {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
    .md\:py-\[18px\] {
        padding-top: 18px;
        padding-bottom: 18px;
    }
    .md\:py-\[60px\] {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .md\:py-\[74px\] {
        padding-top: 74px;
        padding-bottom: 74px;
    }
    .md\:py-\[90px\] {
        padding-top: 90px;
        padding-bottom: 90px;
    }
    .md\:pb-0 {
        padding-bottom: 0;
    }
    .md\:pb-11 {
        padding-bottom: 2.75rem;
    }
    .md\:pb-12 {
        padding-bottom: 3rem;
    }
    .md\:pb-4 {
        padding-bottom: 1rem;
    }
    .md\:pb-\[18px\] {
        padding-bottom: 18px;
    }
    .md\:pe-3 {
        padding-inline-end: 0.75rem;
    }
    .md\:pe-3\.5 {
        padding-inline-end: 0.875rem;
    }
    .md\:pe-\[15px\] {
        padding-inline-end: 15px;
    }
    .md\:ps-9 {
        padding-inline-start: 2.25rem;
    }
    .md\:pt-0 {
        padding-top: 0;
    }
    .md\:pt-0\.5 {
        padding-top: 0.125rem;
    }
    .md\:pt-10 {
        padding-top: 2.5rem;
    }
    .md\:pt-20 {
        padding-top: 5rem;
    }
    .md\:pt-4 {
        padding-top: 1rem;
    }
    .md\:pt-6 {
        padding-top: 1.5rem;
    }
    .md\:pt-\[19\.106\%\] {
        padding-top: 19.106%;
    }
    .md\:pt-\[27px\] {
        padding-top: 27px;
    }
    .md\:pt-\[52px\] {
        padding-top: 52px;
    }
    .md\:text-center {
        text-align: center;
    }
    .md\:text-end {
        text-align: end;
    }
    .md\:text-2xl\/none {
        font-size: 1.5rem;
        line-height: 1;
    }
    .md\:text-4xl\/none {
        font-size: 2.25rem;
        line-height: 1;
    }
    .md\:text-\[32px\] {
        font-size: 32px;
    }
    .md\:text-base {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .md\:text-base\/5 {
        font-size: 1rem;
        line-height: 1.25rem;
    }
    .md\:text-base\/none {
        font-size: 1rem;
        line-height: 1;
    }
    .md\:text-lg {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
    .md\:text-lg\/none {
        font-size: 1.125rem;
        line-height: 1;
    }
    .md\:text-sm {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
    .md\:text-sm\/\[22px\] {
        font-size: 0.875rem;
        line-height: 22px;
    }
    .md\:text-sm\/none {
        font-size: 0.875rem;
        line-height: 1;
    }
    .md\:text-sm\/normal {
        font-size: 0.875rem;
        line-height: 1.5;
    }
    .md\:text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
    .md\:text-xl\/none {
        font-size: 1.25rem;
        line-height: 1;
    }
    .md\:text-xs {
        font-size: 0.75rem;
        line-height: 1rem;
    }
    .md\:text-xs\/none {
        font-size: 0.75rem;
        line-height: 1;
    }
    .md\:font-bold {
        font-weight: 700;
    }
    .md\:leading-none {
        line-height: 1;
    }
    .md\:text-\[\#A0A0A0\] {
        --tw-text-opacity: 1;
        color: rgb(160 160 160 / var(--tw-text-opacity));
    }
    .md\:opacity-50 {
        opacity: 0.5;
    }
    .md\:shadow-\[0_9px_28px_8px_rgba\(0\,0\,0\,0\.05\)\] {
        --tw-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        --tw-shadow-colored: 0 9px 28px 8px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
    .md\:transition-colors {
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 0.15s;
    }
    .md\:scroll-shadow-bottom {
        background: linear-gradient(transparent, white 50%) center bottom, linear-gradient(transparent, rgba(0, 0, 0, 0.04)) center bottom;
        background-repeat: no-repeat;
        background-size: 100% 24px, 100% 12px;
        background-attachment: local, scroll;
    }
    .dark .md\:scroll-shadow-bottom {
        background: linear-gradient(transparent, #282938 50%) center bottom, linear-gradient(transparent, rgba(0, 0, 0, 0.2)) center bottom;
        background-repeat: no-repeat;
        background-size: 100% 24px, 100% 12px;
        background-attachment: local, scroll;
    }
    .md\:hover\:bg-\[\#F5F5F5\]:hover {
        --tw-bg-opacity: 1;
        background-color: rgb(245 245 245 / var(--tw-bg-opacity));
    }
    .md\:hover\:bg-\[\#FAFAFA\]:hover {
        --tw-bg-opacity: 1;
        background-color: rgb(250 250 250 / var(--tw-bg-opacity));
    }
    .group:hover .md\:group-hover\:block {
        display: block;
    }
    .md\:aria-checked\:h-56[aria-checked='true'] {
        height: 14rem;
    }
    .md\:aria-checked\:bg-\[linear-gradient\(209deg\,\#DA1C1C_-7\.14\%\,\#8C1515_102\.95\%\)\][aria-checked='true'] {
        background-image: linear-gradient(209deg, #da1c1c -7.14%, #8c1515 102.95%);
    }
    .group[aria-checked='true'] .md\:group-aria-checked\:-end-2 {
        inset-inline-end: -0.5rem;
    }
    .group[aria-checked='true'] .md\:group-aria-checked\:mt-2 {
        margin-top: 0.5rem;
    }
    .group[aria-checked='true'] .md\:group-aria-checked\:border-s-\[\#FFD86D\]\/\[0\.763904\] {
        border-inline-start-color: rgba(255, 216, 109, 0.765);
    }
    .group[aria-checked='true'] .md\:group-aria-checked\:border-t-\[\#FFD86D\]\/\[0\.763904\] {
        border-top-color: rgba(255, 216, 109, 0.765);
    }
    .group[aria-checked='true'] .md\:group-aria-checked\:bg-\[\#FFD86D\]\/\[0\.763904\] {
        background-color: rgba(255, 216, 109, 0.765);
    }
    :is(.dark .dark\:md\:from-theme-dark) {
        --tw-gradient-from: #282938 var(--tw-gradient-from-position);
        --tw-gradient-to: rgb(40 41 56 / 0) var(--tw-gradient-to-position);
        --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
    }
}
@media (min-width: 1024px) {
    .lg\:mb-2 {
        margin-bottom: 0.5rem;
    }
    .lg\:mb-2\.5 {
        margin-bottom: 0.625rem;
    }
    .lg\:mb-\[28px\] {
        margin-bottom: 28px;
    }
    .lg\:ms-5 {
        margin-inline-start: 1.25rem;
    }
    .lg\:mt-6 {
        margin-top: 1.5rem;
    }
    .lg\:h-60 {
        height: 15rem;
    }
    .lg\:h-\[72px\] {
        height: 72px;
    }
    .lg\:max-h-\[80\%\] {
        max-height: 80%;
    }
    .lg\:w-28 {
        width: 7rem;
    }
    .lg\:w-\[72px\] {
        width: 72px;
    }
    .lg\:max-w-96 {
        max-width: 24rem;
    }
    .lg\:max-w-\[400px\] {
        max-width: 400px;
    }
    .lg\:max-w-\[80\%\] {
        max-width: 80%;
    }
    .lg\:grid-cols-8 {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    .lg\:gap-x-2 {
        -moz-column-gap: 0.5rem;
        column-gap: 0.5rem;
    }
    .lg\:gap-x-2\.5 {
        -moz-column-gap: 0.625rem;
        column-gap: 0.625rem;
    }
    .lg\:rounded-lg {
        border-radius: 0.5rem;
    }
    .lg\:rounded-none {
        border-radius: 0;
    }
    .lg\:p-6 {
        padding: 1.5rem;
    }
    .lg\:px-0 {
        padding-left: 0;
        padding-right: 0;
    }
    .lg\:px-10 {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
    .lg\:px-12 {
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .lg\:py-10 {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
    .lg\:py-5 {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }
    .lg\:py-8 {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .lg\:pb-9 {
        padding-bottom: 2.25rem;
    }
    .lg\:pt-8 {
        padding-top: 2rem;
    }
    .lg\:pt-9 {
        padding-top: 2.25rem;
    }
    .lg\:text-2xl\/none {
        font-size: 1.5rem;
        line-height: 1;
    }
    .lg\:text-lg {
        font-size: 1.125rem;
        line-height: 1.75rem;
    }
    .lg\:text-sm {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
    .lg\:text-sm\/none {
        font-size: 0.875rem;
        line-height: 1;
    }
    .lg\:text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
    .lg\:aria-checked\:h-64[aria-checked='true'] {
        height: 16rem;
    }
    .group[aria-checked='true'] .lg\:group-aria-checked\:mt-3 {
        margin-top: 0.75rem;
    }
    .group[aria-checked='true'] .lg\:group-aria-checked\:w-32 {
        width: 8rem;
    }
}
@media (orientation: landscape) {
    .landscape\:w-\[123vmin\] {
        width: 123vmin;
    }
}
.\[\&_\[style\*\=bold\]\]\:\!font-medium [style*='bold'] {
    font-weight: 500 !important;
}
.\[\&_\[style\*\=bold\]\]\:text-text-primary [style*='bold'] {
    --tw-text-opacity: 1;
    color: rgb(50 50 50 / var(--tw-text-opacity));
}
.\[\&_b\]\:\!font-medium b {
    font-weight: 500 !important;
}
.\[\&_b\]\:text-text-primary b {
    --tw-text-opacity: 1;
    color: rgb(50 50 50 / var(--tw-text-opacity));
}
.\[\&_div\[data-marker\]\]\:hidden div[data-marker] {
    display: none;
}
.\[\&_strong\]\:\!font-medium strong {
    font-weight: 500 !important;
}
.\[\&_strong\]\:text-text-primary strong {
    --tw-text-opacity: 1;
    color: rgb(50 50 50 / var(--tw-text-opacity));
}
style[id^='ddStyleCaptchaBody'] + div {
    background: transparent !important;
}
style[id^='ddStyleCaptchaBody'] + div iframe {
    width: 100%;
    height: 100% !important;
}

@media (max-width: 745px) {
    .mt-10 {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
}
