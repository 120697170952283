* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#login-button:disabled {
    background-color: #d81a0d;
    color: white;
}

.denomItem.disabled {
    opacity: 0.5;
    pointer-events: none;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Định nghĩa lớp loading cho thẻ body */
.body-loading {
    position: relative;
}

@keyframes rotateBackground {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.body-loading::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9998;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
}

.body-loading::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('./assets/image/loading.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100px;
    animation: rotateBackground 2s linear infinite;
    transform-origin: center;
    z-index: 9999;
}

@keyframes fadeUpIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeDownOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(20px);
    }
}

.fadeIn {
    animation: fadeUpIn 0.3s ease-out forwards;
}

.fadeOut {
    animation: fadeDownOut 0.3s ease-out forwards;
}

@keyframes rotate180 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
}

.rotate-180 {
    animation: rotate180 0.3s forwards;
}

@keyframes rotate180Reverse {
    from {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(0deg);
    }
}

.rotate-180-reverse {
    animation: rotate180Reverse 0.3s forwards;
}

#sidebarLogin {
    position: fixed;
    top: 0;
    right: -286px; /* Ban đầu sidebar sẽ bị ẩn ngoài màn hình */
    width: 286px;
    height: 100%;
    background: white;
    transition: right 0.3s ease; /* Thời gian và kiểu animation */
}

#sidebarLogin.open {
    right: 0; /* Khi có class 'open', sidebar sẽ di chuyển vào màn hình */
}

.button-loading {
    visibility: hidden;
    opacity: 0;
}

.btn-loading::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 2px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
    border-right-color: rgba(255, 255, 255, 0.404);
    border-left-color: rgba(255, 255, 255, 0.404);
    border-bottom-color: rgba(255, 255, 255, 0.404);
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}

.swiper {
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
}

.game-checked {
    background-image: linear-gradient(-45deg, transparent 50%, #d81a0d 50%);
}

.my-5 {
    margin-top: 38px !important;
    margin-bottom: 38px !important;
}
