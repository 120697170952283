.ae31e73fAddf403e8b5128564191c40a,
.f9c0cab84013455c89e8B2bcfa47c881.d270693a57434b82B843Aed3c22241a8_show pre {
    margin: 0 !important;
    padding: 0 !important;
}

#d270693a57434b82B843Aed3c22241a8 {
    position: fixed;
    z-index: 6001;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    background: #3f51b5;
    text-align: center;
    line-height: 2.5;
    overflow: hidden;
    -webkit-box-shadow: 0 0 5px #000;
    -moz-box-shadow: 0 0 5px #000;
    box-shadow: 0 0 5px #000;
    transition: all 0.5s ease-in-out;
}

#d270693a57434b82B843Aed3c22241a8 .d270693a57434b82B843Aed3c22241a8_content {
    padding-left: 50px;
    max-width: calc(100% - 100px);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#d270693a57434b82B843Aed3c22241a8 .d270693a57434b82B843Aed3c22241a8_content .d270693a57434b82B843Aed3c22241a8_title-content {
    color: #fff;
}

#d270693a57434b82B843Aed3c22241a8 .d270693a57434b82B843Aed3c22241a8_content .d270693a57434b82B843Aed3c22241a8_btn_confirm {
    background-color: #000;
    padding: 7px;
    border-radius: 10px;
    text-decoration: none;
    color: #fff;
}

#d270693a57434b82B843Aed3c22241a8 .d270693a57434b82B843Aed3c22241a8_content .d270693a57434b82B843Aed3c22241a8_btn_confirm:hover {
    background-color: #3f3d3d;
}

#d270693a57434b82B843Aed3c22241a8 .d270693a57434b82B843Aed3c22241a8_content .d270693a57434b82B843Aed3c22241a8_btn_close {
    color: #fff;
}

#d270693a57434b82B843Aed3c22241a8 .d270693a57434b82B843Aed3c22241a8_content .d270693a57434b82B843Aed3c22241a8_title {
    flex: 1;
}

#d270693a57434b82B843Aed3c22241a8.d270693a57434b82B843Aed3c22241a8_show {
    height: 40px;
}
